import React, { useState } from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import './Navbar.css'; 
import logo from '../Pictures/logo.png'; 
import { FaBars } from 'react-icons/fa';  

export default function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);  // Toggle the hamburger menu on mobile screens
    };

    return (
        <header className="navbar sticky-top navbar-light bg-light">
            <div className="navbar-container">
            <Link to="/" className="logo-container">
            <img src={logo} alt="Logo" className="logo" />
            </Link>
                
                {/* Hamburger Icon for Mobile View */}
                <FaBars className="hamburger-icon" onClick={toggleMenu} />

                {/* Nav Menu, hidden or shown based on state */}
                <nav className={`nav-menu ${isMenuOpen ? 'open' : ''}`}>
                    <ul className="nav-links">
                        <CustomLink to="/">Home</CustomLink>
                        <CustomLink to="/mediSupport-hub">MediSupport Hub</CustomLink>
                        <CustomLink to="/gp-locator">GP Locator</CustomLink>
                        {/*  <CustomLink to="/event">Event</CustomLink>*/}
                    </ul>
                </nav>
            </div>
        </header>
    );
}

function CustomLink({ to, children, ...props }) {
    const resolved = useResolvedPath(to);
    const match = useMatch({ path: resolved.pathname, end: true });

    return (
        <li className={match ? 'active' : ''}>
            <Link to={to} {...props}>{children}</Link>
        </li>
    );
}


