import React, { useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import womenImage from '../Pictures/Women.png';  
import { FaUserDoctor, FaMapLocation } from 'react-icons/fa6';
import { GrLanguage } from "react-icons/gr";
import doctor from '../Pictures/doctor.jpg'; 
import map from '../Pictures/map.png';
import { FaArrowUp } from 'react-icons/fa'; 

function Home() {
    const [showScrollTop, setShowScrollTop] = useState(false);
    // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Show or hide the "Back to Top" button based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {  // Adjust this value to decide when the button appears
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
    return (
      <div>
        <div className="home-container">
          <div className="right-content">
                <h1>Welcome to Migrant Women Health Website </h1>
                <p>Begin with exploring how to book a GP and learn more about medical knowledge</p>
                <div className="buttons">
                    <Link to="/mediSupport-hub" className="btn">Explore MediSupport Hub</Link>
                </div>
            </div>
            <div className="left-content">
                <img src={womenImage} alt="Supporting Women" />
            </div>        
        </div>
        <div className="features-section">
          <h2>What We Do for You</h2>
          <div className="features">
              <div className="feature">
                  <GrLanguage size={50} />
                  <h5>Language</h5>
                  <p>Breaking down language barriers</p>
              </div>
              <div className="feature">
                  <FaUserDoctor size={50} />
                  <h5>GP Process </h5>
                  <p>Help you with how to book a GP, what medical information you can learn.</p>
              </div>
              <div className="feature">
                  <FaMapLocation size={50} />
                  <h5>GP Location</h5>
                  <p>Support you find a suitable GP.</p>
              </div>
          </div>
      </div> 
      {/* Further Support Section */}
      <div className="generation-section">
        <h2 className="section-title">Supporting You Further</h2>
        <div className="generation-cards">
          <div className="generation-card">
            <img src={doctor} alt="Supporting Women" className="fixed-image" />
            <div className="card-text">
              <h3>Helping Refugee Women Access Healthcare</h3>
              <p>Our platform tackles the healthcare barriers migrant women face in Australia. We provide guidance to find services that are both affordable and culturally appropriate.</p>
              <p>Explore our Health Support Center to schedule appointments, learn about health benefits, and ensure healthcare is affordable and accessible.</p>
              <Link to="/mediSupport-hub" className="btn">Explore MediSupport</Link>
            </div>
          </div>
          <div className="generation-card">
            <div className="card-text">
              <h3>Navigating Healthcare with the GP Locator</h3>
              <p>Our GP Locator tool helps migrant women navigate the healthcare system. The map highlights clinics based on pricing and services, providing insights into clinic details and Medicare coverage.</p>
              <Link to="/gp-locator" className="btn">Explore GP Locator</Link>
            </div>
            <img src={map} alt="GP Locator" className="fixed-image" />
          </div>
        </div>
      </div>

      {/* Scroll to Top Button */}
      {showScrollTop && (
        <button className="scroll-top-button" onClick={scrollToTop}>
          <FaArrowUp size={20} />
        </button>
      )}
    </div>
  );
}


export default Home;